import Header2 from '../components/Header2'
import Sidebar from '../components/Sidebar'
import { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { verifyUser } from '../auth/verifyUser'
import axios from 'axios'
import { storeBox, getBox } from '../auth/storage'
import ClipLoader from 'react-spinners/ClipLoader'
import { UserContext } from '../App'

import {
	checkEmail,
	checkPassword,
	checkConfirmPassword,
	checkCheckbox,
	baseUrl,
} from '../helpers/auth.helper'

const roleList = [
	{ value: 'User Role', text: 'User Role' },
	{ value: 'Administrator', text: 'Administrator' },
	{ value: 'Student', text: 'Student' },
]

const roles = roleList.map((role) => (
	<option value={role.value} key={role} disabled={role.disabled}>
		{role.text}
	</option>
))

const EditUsers = () => {
	const [role, setUserRole] = useState('User Role')
	const [roleError, setRoleError] = useState('')
	const [user, setEditingUser] = useState(null)

	const [userFirstName, setUserFirstName] = useState('')
	const [userFirstNameError, setUserFirstNameError] = useState('')
	const [userLastName, setUserLastName] = useState('')
	const [userLastNameError, setUserLastNameError] = useState('')
	const [userAddress, setUserAddress] = useState('')
	const [userEmail, setUserEmail] = useState('')
	const [emailError, setEmailError] = useState('')

	const [formError, setFormError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const { setUser } = useContext(UserContext)
	const history = useHistory()
	let { id } = useParams()

	console.log(id, 'iddd')

	useEffect(() => {
		getEditingUser()
		return () => {
			setFormError('')
		}
	}, [isLoading])

	const handleRoleChange = (role) => {
		setUserRole(role)
		if (location === 'Role') setCountryError('Choose a role.')
		else setRoleError('')
	}

	const updateEmail = (val) => {
		setUserEmail(val)
		var result = checkEmail(val)
		setEmailError(result.msg)
	}

	const updateFirstName = (val) => {
		val == false
			? setUserFirstNameError('Please provide a name for the user')
			: setUserFirstName(val)
	}

	const handleEditUser = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		setFormError('')
		setIsError(false)
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/editUser/${id}`
		const method = 'put'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			user_role: role,
			first_name: userFirstName,
			last_name: userLastName,
			email: userEmail,
			user_address: userAddress,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			console.log('here')

			const response = await axios(config)

			console.log(response, 'respp')

			setFormError(response.msg)

			setIsLoading(false)

			history.push('/users')
		} catch (err) {
			setFormError(err.msg)
			setIsLoading(false)
			setIsError(true)
		}
	}

	const getEditingUser = async () => {
		// event.preventDefault()
		// setIsLoading(true)
		setFormError('')
		setIsError(false)
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}}/user/getUser/${id}`
		const method = 'get'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		// const data = {
		// 	user_role: role,
		// 	first_name: userFirstName,
		// 	last_name: userLastName,
		// 	email: userEmail,
		// 	user_address: userAddress,
		// }
		const config = {
			method,
			url,
			headers,
		}
		try {
			console.log('here')

			const response = await axios(config)

			// setEditingUser(response.data.user)

			setUserFirstName(response.data.user.first_name)
			setUserLastName(response.data.user.last_name)
			setUserEmail(response.data.user.email)
			setUserAddress(response.data.user.location)
			setUserRole(response.data.user.role)
			console.log(response, 'respp')

			setFormError(response.msg)

			setIsLoading(false)
		} catch (err) {
			setFormError(err.msg)
			setIsLoading(false)
			setIsError(true)
		}
	}

	return (
		<div className='organization_page'>
			<Header2 />
			<div className='row'>
				{/* <div className='col-lg-2 col d-lg-block d-none'>
					<Sidebar />
				</div> */}
				<div className='col-lg-10 col col-12'>
					<div className='page_content'>
						<h2>Edit User</h2>
						<div className='org_card'>
							<div className='org_form'>
								<select
									value={role}
									onChange={(event) => handleRoleChange(event.target.value)}
									className='form-select form-select-lg mb-4'
									aria-label='Default select example'
								>
									<option value='User Role' disabled hidden>
										User Role
									</option>
									<option value='Administrator'>Administrator</option>
									<option value='Student'>Student</option>
									{/* {roles} */}
								</select>

								<input
									value={userFirstName}
									autoComplete='url'
									className='form-control form-control-lg mb-4'
									type='text'
									placeholder='First Name'
									onChange={(event) => updateFirstName(event.target.value)}
								/>
								<p className='error'> {userFirstNameError}</p>
								<input
									value={userLastName}
									autoComplete='url'
									className='form-control form-control-lg mb-4'
									type='text'
									placeholder='Last Name'
									onChange={(event) => setUserLastName(event.target.value)}
								/>

								<input
									value={userEmail}
									autoComplete='url'
									className='form-control form-control-lg mb-4'
									type='email'
									placeholder='User Email'
									onChange={(event) => updateEmail(event.target.value)}
								/>
								<p className='error'> {emailError}</p>
								<input
									value={userAddress}
									autoComplete='url'
									className='form-control form-control-lg mb-4'
									type='text'
									placeholder='Mail Address'
									onChange={(event) => setUserAddress(event.target.value)}
								/>
								<div className='button_container'>
									<button onClick={handleEditUser}>
										{isLoading && (
											<>
												<span>Edditing user ... </span>
												<ClipLoader
													color={'#ffffff'}
													loading={isLoading}
													size={15}
												/>
											</>
										)}
										{!isLoading && <span>Edit</span>}
									</button>
								</div>
								<p className={isError ? 'error' : 'success'}>{formError}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditUsers
